import React, { useState } from "react"
import { Row, Col, Button } from "reactstrap"
import SpinnerChase from "components/Shared/SpinnerChase";
import PropTypes from 'prop-types'

const HelpItem = ({ article, resetHelpContent }) => {

  // whenever article are loading
  const [isLoadInProgress, setIsLoadInProgress] = useState(true);

  return <React.Fragment>
    <Row>
      <Col>
        <div className="d-flex flex-row align-items-center justify-content-center">
          <Button color="none" className="ps-0 mt-1" onClick={resetHelpContent}>
            <i className="mdi mdi-chevron-left font-size-24 me-2" />
          </Button>
          <h2 className="mt-3">{article.title}</h2>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="d-flex flex-column align-items-center">
          <div className="article-item-container">
            <div>
              {isLoadInProgress &&
                <SpinnerChase className="sm help-spinner mt-5" />
              }
              <iframe className="article-iframe" src={article.content?.iframeSrc} onLoad={() => setIsLoadInProgress(false)} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </React.Fragment>
}

HelpItem.propTypes = {
  article: PropTypes.any,
  resetHelpContent: PropTypes.func
}

export default HelpItem;
