import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Label, FormFeedback, Form } from "reactstrap";
import Select from "react-select";
import FormNewUpload from "../../Edit/Upload";
import { useFormikContext } from "formik";
import { dictionaryToSelectOptions, showError, showSuccess } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import Credential from "model/credential";

const FormEditCredential = ({ credential, refreshHandler }) => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  // consume formik instance from context
  const formik = useFormikContext();

  // get redux state from store
  const { uploaded, uploadError } = useSelector(state => state.Credential.Form);

  // we use this as a way to force react to unmount and recreate components
  // this seems to be the only way to clear the select control
  const [nonce, setNonce] = useState(1);

  /********** EFFECTS **********/

  // runs whenever the 'uploaded' flag changes
  // which happens after a upload-doc attempt
  useEffect(() => {
    if (uploaded === true) {
      showSuccess("Credential has been saved");
      refreshHandler()
      // reset upload form fields
      // this is needed because the select control cannot be reset easily
      setNonce(n => n + 1);

    } else if (uploaded === false) {
      showError("Unable to save credential");
      // see if the save failed due to validation
      if (uploadError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(uploadError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [uploaded]);

  const selectedOption = typeOptions.find(option => option.value === formik.values?.type);

  return <Form>
    <Row className="mb-4">
      <Col xs={12} md={6}>
        <Label className="col-form-label">Credential Type*</Label>
        <Select
          isDisabled
          classNamePrefix="select2-selection"
          name="type"
          value={selectedOption}
          className={!!formik.errors.type && "is-invalid"} />
      </Col>
    </Row>

    {formik.values?.type !== "" && <FormNewUpload />}

  </Form>
}

const typeOptions = dictionaryToSelectOptions(Credential.getTypeMap()).map(option => ({ ...option, label: option.label + (Credential.isRequired(option.value) ? "*" : "") }));

FormEditCredential.propTypes = {
  credential: PropTypes.object.isRequired,
  refreshHandler: PropTypes.func.isRequired,
};

export default FormEditCredential;