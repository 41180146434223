import React, { useState } from "react";
import { CardBody, Table, Button, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { useSelector } from "react-redux";
import { perms, useAccess } from "context/access";
import PropTypes from "prop-types";
import TotalCosts from "../Components/TotalCosts";
import AccountingNotes from "../Components/AccountingNotes";
import Order from "model/order";
import { createOrderNotaryStatement } from "helpers/backendHelper";
import Confirmation from "components/Shared/Confirmation";
import { showError, showSuccess } from "helpers/utilHelper";

const NotaryTabContent = ({ fees, ignoreStatement, refreshOrderHandler }) => {

  // get redux state from the store
  const { order } = useSelector(state => state.Order.Single);

  const [isCreateStatementConfVisible, setIsCreateStatementConfVisible] = useState(false);
  const [isCreateStatementInProgress, setIsCreateStatementInProgress] = useState(false);

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const totalNotaryFee = fees?.reduce((accum, val) => accum + (Number(val.price) * Number(val.count ?? 1)), 0);

  // check if ignore button should be visible
  const ignoreStatementVisible = order.status == Order.STATUS_CANCELLED && ![Order.ACCOUNTING_STATUS_SETTLED, Order.ACCOUNTING_STATUS_IGNORED].includes(order.accountingStatus);

  // check if exist fees with price set
  const isPriceNotSet = fees?.some(fee => (fee.price === "" || fee.price === null));

  /********** OTHER **********/
  // check if the fee price is 0 and display the Not set message,
  // otherwise display the fee price multiplied with count (count = how many fee exist of that type)
  const checkFeePrice = (price, count) => {
    if (price === "" || price === null) {
      return 'Not set';
    }
    return `${Number(price) * Number(count ?? 1)}$`;
  }

  const createStatement = () => {
    setIsCreateStatementInProgress(true);
    createOrderNotaryStatement(order.id)
      .then(response => {
        showSuccess(`Notary statement #${response.id} has been created`);
        refreshOrderHandler();
      })
      .catch(ex => {
        showError('Unable to create notary statement');
      })
      .finally(() => {
        setIsCreateStatementInProgress(false);
      });
  }


  return (<CardBody className="px-0">
    <Table className="section-rows table bt-1 view-accounting">
      <tbody>
        {fees?.map((item, index) => {
          if (item) {
            return (
              <tr key={index}>
                <td className="section-row-label">{item.additionalFeeName}</td>
                <td className={classnames('section-row-value text-right ps-4')}>{checkFeePrice(item.price, item.count)}</td>
              </tr>
            )
          }
        })}
      </tbody>
    </Table>

    {(fees?.length === 0 || fees?.every(fee => fee === null)) && (<p className="mb-4">There are no fees for this order.</p>)}
    {!isPriceNotSet && <TotalCosts label={'Notary’s Total Cost'} value={totalNotaryFee || 0} />}
    {order?.notaryAccountingNotes && <AccountingNotes title={"Notary notes to Accounting"} message={order.notaryAccountingNotes} />}
    {order?.accountingReview && <AccountingNotes title={"Accounting Review"} message={order.accountingReviewReason} />}

    <div className="text-end mt-4">
      {iAmGranted(perms.view_statements) && !!order.notaryStatementId && <Link to={route(routes.view_notary_statement, { id: order.notaryStatementId })} className="btn btn-info mb-2 me-2">
        View Statement
      </Link>}
      {iAmGranted(perms.mark_orders_as_ready_for_billing) && order.notaryStatementMissing && <div id="generate-statement-btn" style={{ display: 'inline-block' }}>
        <Button color="danger" onClick={() => setIsCreateStatementConfVisible(true)} disabled={isCreateStatementInProgress} className="mb-2 me-2" id="create-notary-statement-btn">
          Create Statement
          {isCreateStatementInProgress && <i className="mdi mdi-spin mdi-loading ms-1" />}
          {!isCreateStatementInProgress && <i className="mdi mdi-information-outline ms-1" />}
        </Button>
        <UncontrolledTooltip placement="top" target="create-notary-statement-btn">A notary statement is expected but missing. Click here to generate it</UncontrolledTooltip>
      </div>}
      {iAmGranted(perms.edit_order_accounting_status) && ignoreStatementVisible && <React.Fragment>
        <div id="ignore-statement-btn" style={{ display: 'inline-block' }}>
          <Button color="danger" onClick={ignoreStatement} className="mb-2 me-2" id="ignore-statement-btn">
            Ignore <i className="mdi mdi-close ms-1" />
          </Button>
        </div>
      </React.Fragment>}
      {iAmGranted(perms.edit_order_fees) && order.canAmendNotaryAmount && <Link to={route(routes.view_accounting_edit, { id: order?.id, customValue: 2 })} className="btn btn-primary mb-2">
        Edit <i className="mdi mdi-arrow-right ms-1" />
      </Link>}
    </div>

    {isCreateStatementConfVisible && <Confirmation
      confirmBtnText="Create Statement"
      onConfirm={() => {
        createStatement();
        setIsCreateStatementConfVisible(false);
      }}
      onCancel={() => setIsCreateStatementConfVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to create the notary statement?</span>
    </Confirmation>}
  </CardBody>)
}

NotaryTabContent.propTypes = {
  fees: PropTypes.array,
  ignoreStatement: PropTypes.func,
  refreshOrderHandler: PropTypes.func,
};

export default NotaryTabContent;