import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Card, CardBody, Row, Col, Alert } from "reactstrap"
import { getSharedTableOptions } from "helpers/utilHelper"
import { doBiddingDtCleanup, getBiddingDt } from "store/actions";
import { getSharedPaginationOptions } from "helpers/utilHelper";
import config from "config";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import manualSearchIcon from "assets/images/white-search-icon.svg";
import viewBiddingIcon from "assets/images/white-view-icon.svg";
import assignNotaryIcon from "assets/images/notary-service-white-icon.svg";

const DataTable = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const { biddings: rows, biddingsError: rowsError, totalCount, listParams, isLoadInProgress } = useSelector(state => state.Bidding.Dt);

  // datatable PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: 50,
    defaultSorted: [{
      dataField: listParams.sortBy,
      order: listParams.sortDir,
    }],
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    dispatch(getBiddingDt({ ...listParams, pageSize: 50 }));
    return () => {
      // state cleanup on component unmount
      dispatch(doBiddingDtCleanup());
    }
  }, []);

  // runs whenever "totalCount" changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let"s update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  /********** EVENT HANDLERS **********/

  // runs whenever table params change (sorting, pagination, search, etc)
  const handleTableChange = (type, newState) => {
    // reset the page number when searching
    // otherwise the current page number might be higher than the total number of pages after the search
    if (type == "search") {
      newState.page = 1;
    }
    // refresh the list data based on the new table params
    dispatch(getBiddingDt({
      ...listParams,
      sortBy: newState.sortField,
      sortDir: newState.sortOrder,
      pageSize: newState.sizePerPage,
      page: newState.page,
      search: newState.searchText,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  return <React.Fragment>
    <Card className="paginated-table-card">
      <CardBody className="pt-3">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={rows}
              search={{ defaultSearch: listParams.search }}>
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col>
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <Search.SearchBar
                            {...toolkitProps.searchProps}
                            delay={config.DATA_TABLE_SEARCH_DELAY}
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...getSharedTableOptions()}
                          noDataIndication={!rowsError && "No biddings  found"}
                          onTableChange={handleTableChange}
                          defaultSorted={paginationProps.defaultSorted}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                      {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                      {!!rowsError && <Alert color="danger" className="fade show text-center">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load biddings
                      </Alert>}
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  </React.Fragment>
}

const columns = [{
  dataField: "dummy",
  text: "Number",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row, rowIndex) => <span>{rowIndex + 1}</span>,
}, {
  dataField: "id",
  text: "Order ID",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <Link to={route(routes.view_order, row.id)}>{cellContent}</Link>
}, {
  dataField: "customerName",
  text: "Customer Name",
  sort: false,
}, {
  dataField: "customerAddress",
  text: "Customer Address",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => cellContent ? cellContent : "--"
}, {
  dataField: "isRushRequired",
  text: "Rush order",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => cellContent ? "Yes" : "No"
}, {
  dataField: "biddingStatus",
  text: "Bidding status",
  sort: false,
}, {
  dataField: "action",
  text: "Action",
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    return <>
      {row.biddingStatus === 'No results' ? <Link to={route(routes.view_order_notary_manual_search, row.id)}>
        <span className={`badge badge-lg bg-primary bg-primary`}>
          <><img className="me-2" src={manualSearchIcon} />Manual search</>
        </span>
      </Link> : <Link to={route(routes.view_order_notary, row.id)}>
        <div className={`badge badge-lg bg-primary bg-primary`}> {row.biddingStatus === 'In progress' ?
          <><img className="me-2" src={viewBiddingIcon} />View bidding</> :
          <><img className="me-2" src={assignNotaryIcon} />Assign Notary</>}
        </div>
      </Link>}
    </>;
  },
},];

export default DataTable;
