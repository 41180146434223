import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Button, Form, Label, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import Select from 'react-select';
import { useFormik } from 'formik';
import {
  dictionaryToSelectOptions,
  extractDtFiltersFromUrl,
  getGranularStatusOptions,
  hasNonEmpty,
  removeDtFiltersFromUrl,
  showError,
  toSelectOptions
} from 'helpers/utilHelper';
import { applyOrderDtFilters, clearOrderDtFilters, unsetOrderDtFilters, patchOrderDtFilters, doOrderDtFiltersCleanup, doDealerGroupListCleanup, doDealerStoreListCleanup, getDealerGroupList, getDealerStoreList } from 'store/actions';
import { isEmpty, omit } from 'lodash';
import Order from 'model/order';
import VidRequest from 'model/vidRequest'
import { getDealerUserList, getPaymentPlans } from "helpers/backendHelper";
import * as Yup from "yup";
import DateRangePicker from 'components/Shared/DateRangePicker';
import { perms, useAccess } from "context/access";

const DataTableFilters = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const filters = useSelector(state => state.Order.DtFilters);
  const { groups, groupsError } = useSelector(state => state.DealerGroup.List);
  const { stores, storesError } = useSelector(state => state.DealerStore.List);
  const [creator, setCreator] = useState([]);
  const [paymentPlans, setPaymentPlans] = useState([]);

  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      range: Yup.array(),
    }),
    initialValues: {
      ...filters,
      range: filters.submittedBetween ? filters.submittedBetween.split("-") : [],
      includeStatus: [],
      incAlert: [],
      vidStatuses: [],
      submittedBetween: '',
      underReviewStatus: [],
      duplicateReviewStatus: [],
    },
    onSubmit: values => applyFilters({ ...omit(values, "range"), submittedBetween: (values.range)?.length > 1 ? values.range.join("-") : null }),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    dispatch(getDealerGroupList());
    dispatch(getDealerStoreList());
    return () => {
      // state cleanup on component unmount
      dispatch(doDealerGroupListCleanup());
      dispatch(doDealerStoreListCleanup());
    }
  }, []);

  // runs once on component mount
  useEffect(() => {
    getCreatorList();
    getPaymentPlansList();
  }, []);

  // runs whenever 'groupsError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (groupsError) {
      // set an error on the form field
      formik.setFieldError("dealerGroup", "Unable to load groups");
    }
  }, [groupsError]);
  // runs whenever 'storesError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (storesError) {
      // set an error on the form field
      formik.setFieldError("dealerStore", "Unable to load stores");
    }
  }, [storesError]);

  // runs once on component mount
  useEffect(() => {
    // extract filters from url
    // they will be applied by default as the user enters the screen
    const filters = extractDtFiltersFromUrl();

    // if filters are not empty, it means we are on a `view all` screen
    if (!isEmpty(filters)) {
      // in this case, replace the old filters with the relevant ones
      applyFilters(filters);
    } else {
      // make sure to call this even if there are no filters in the url
      // because we need to switch the '_set' flag
      dispatch(patchOrderDtFilters(filters));
    }

    return () => {
      // this helps us cleanup filters only when on a `view all` filtered screen
      if (!isEmpty(filters)) {
        dispatch(doOrderDtFiltersCleanup());
      } else {
        dispatch(unsetOrderDtFilters()); // persist filters applied by user, but mark them as unset
      }
    }
  }, []);

  /********** EVENT HANDLERS **********/

  const handleChangeRange = selected => {
    formik.setFieldValue("range", selected);
  };

  const handleClearRange = () => {
    formik.setFieldValue("range", []);
  };

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => {
    dispatch(applyOrderDtFilters(values));
  }

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setValues({
      ...formik.initialValues,
      includeService: [], // Clear selected values in "Services included"
      includeStatus: [], // Clear selected values in "Status included"
      vidStatuses: [], // Clear selected values in "Vid Status"
      incAlert: [], // Clear selected values in "Alert"
      underReviewStatus: [],
      duplicateReviewStatus: [],
    });
    // reset state
    dispatch(clearOrderDtFilters());
    // clear url filters
    navigate({ search: removeDtFiltersFromUrl() }, { replace: true });
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas
  const initLocalFilters = () => formik.setValues(filters);

  const setStatusOptions = (statusList) => {
    const selectedValues = statusList.map((serviceItem) => serviceItem.value);
    formik.setFieldValue("includeStatus", selectedValues);
  }

  const setVidOptions = (statusList) => {
    const selectedValues = statusList.map((serviceItem) => serviceItem.value);
    formik.setFieldValue("vidStatuses", selectedValues);
  }

  const setAlertOptions = (alertList) => {
    const selectedValues = alertList.map((serviceItem) => serviceItem.value);
    formik.setFieldValue("incAlert", selectedValues);
  }

  const setUnderReviewOptions = (reviewList) => {
    const selectedValues = reviewList.map((reviewItem) => reviewItem.value);
    formik.setFieldValue("underReviewStatus", selectedValues);
  }

  const setDuplicateReviewOptions = (reviewList) => {
    const selectedValues = reviewList.map((reviewItem) => reviewItem.value);
    formik.setFieldValue("duplicateReviewStatus", selectedValues);
  }

  /********** OTHER **********/

  const getCreatorList = () => {
    getDealerUserList()
      .then((response) => {
        const creatorsList = response.users.map(item => ({ id: item.id, name: item.fullName }))
        setCreator(creatorsList);
      })
      .catch(err => {
        showError('Unable to load data');
      })
  }

  const getPaymentPlansList = () => {
    getPaymentPlans()
      .then(response => {
        setPaymentPlans(response.paymentPlans);
      })
      .catch(() => {
        showError("Unable to load Payment Plans data.");
      })
  };

  const hasFilters = () => hasNonEmpty(omit(filters, "_set"));

  // adds 'All' to the list of groups
  // prepares the list to be used as select options
  const getGroupOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(groups)];
  }
  // adds 'All' to the list of stores
  // prepares the list to be used as select options
  const getStoreOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(stores)];
  }

  // adds 'All' to the list of payment plans
  // prepares the list to be used as select options
  const getPaymentPlansOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(paymentPlans)];
  }

  // adds 'All' to the list of creators
  // prepares the list to be used as select options
  const getCreatorOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(creator)];
  }

  const statusOptions = getGranularStatusOptions();

  const getVidOptions = () => dictionaryToSelectOptions(VidRequest.getVidStatusesForOrders());

  const getAlertOptions = () => dictionaryToSelectOptions(Order.getAlertOptions());

  const getBillingStatusOptions = () => dictionaryToSelectOptions(Order.getBillingStatusFiltersMap());

  const getAccountingReviewOptions = () => dictionaryToSelectOptions(Order.getAccountingReviewMap());

  const getOnHoldReviewOptions = () => dictionaryToSelectOptions(Order.getOnHoldReviewMap());

  const getUnderReviewOptions = () => dictionaryToSelectOptions(Order.getUnderReviewMap());

  const getDuplicateReviewOptions = () => dictionaryToSelectOptions(Order.getDuplicateReviewMap());

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Submitted Between</Label>
            <DateRangePicker
              onChange={handleChangeRange}
              value={formik.values.range ?? []}
              onClear={handleClearRange}
            />
            {!!formik.errors.range && <FormFeedback type="invalid">{formik.errors.range}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Creator</Label>
            <Select
              classNamePrefix="select2-selection"
              name="userId"
              options={getCreatorOptions()}
              onChange={selected => formik.setFieldValue("userId", selected.value)}
              value={getCreatorOptions().find(option => option.value === formik.values.userId)}
              className={!!formik.errors.userId && "is-invalid"} />
            {!!formik.errors.userId && <FormFeedback type="invalid">{formik.errors.userId}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Group</Label>
            <Select
              classNamePrefix="select2-selection"
              name="dealerGroup"
              options={getGroupOptions()}
              onChange={selected => formik.setFieldValue("dealerGroup", selected.value)}
              value={getGroupOptions().find(option => option.value === formik.values.dealerGroup)}
              className={!!formik.errors.dealerGroup && "is-invalid"} />
            {!!formik.errors.dealerGroup && <FormFeedback type="invalid">{formik.errors.dealerGroup}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Store</Label>
            <Select
              classNamePrefix="select2-selection"
              name="dealerStore"
              options={getStoreOptions()}
              onChange={selected => formik.setFieldValue("dealerStore", selected.value)}
              value={getStoreOptions().find(option => option.value === formik.values.dealerStore)}
              className={!!formik.errors.dealerStore && "is-invalid"} />
            {!!formik.errors.dealerStore && <FormFeedback type="invalid">{formik.errors.dealerStore}</FormFeedback>}
          </div>
          {iAmGranted(perms.view_payment_plans_filter) && <div className="mb-3">
            <Label>Payment plan</Label>
            <Select
              classNamePrefix="select2-selection"
              name="paymentPlan"
              options={getPaymentPlansOptions()}
              onChange={selected => formik.setFieldValue("paymentPlan", selected.value)}
              value={getPaymentPlansOptions().find(option => option.value === formik.values.paymentPlan)}
              className={!!formik.errors.paymentPlan && "is-invalid"} />
            {!!formik.errors.paymentPlan && <FormFeedback type="invalid">{formik.errors.paymentPlan}</FormFeedback>}
          </div>}
          <div className="mt-2 mb-3">
            <Label>Status included</Label>
            <Select
              classNamePrefix="select2-selection"
              name="includeStatus"
              isMulti
              options={statusOptions}
              onChange={selected => setStatusOptions(selected)}
              value={statusOptions.filter(option => formik.values.includeStatus.includes(option.value))}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>VID status</Label>
            <Select
              classNamePrefix="select2-selection"
              name="vidStatuses"
              isMulti
              options={getVidOptions()}
              onChange={selected => setVidOptions(selected)}
              value={getVidOptions().filter(option => formik.values.vidStatuses.includes(option.value))}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>Alerts</Label>
            <Select
              classNamePrefix="select2-selection"
              name="incAlert"
              isMulti
              options={getAlertOptions()}
              onChange={selected => setAlertOptions(selected)}
              value={getAlertOptions().filter(option => formik.values.incAlert.includes(option.value))}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>Accounting review</Label>
            <Select
              classNamePrefix="select2-selection"
              name="accountingReviewStatus"
              options={getAccountingReviewOptions()}
              onChange={selected => formik.setFieldValue("accountingReviewStatus", selected.value)}
              value={getAccountingReviewOptions().find(option => option.value === formik.values.accountingReviewStatus)}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>On Hold</Label>
            <Select
              classNamePrefix="select2-selection"
              name="onHoldReviewStatus"
              options={getOnHoldReviewOptions()}
              onChange={selected => formik.setFieldValue("onHoldReviewStatus", selected.value)}
              value={getOnHoldReviewOptions().find(option => option.value === formik.values.onHoldReviewStatus)}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>Under Review</Label>
            <Select
              classNamePrefix="select2-selection"
              name="underReviewStatus"
              isMulti
              options={getUnderReviewOptions()}
              onChange={selected => setUnderReviewOptions(selected)}
              value={getUnderReviewOptions().filter(option => formik.values.underReviewStatus.includes(option.value))}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>Duplicate Review</Label>
            <Select
              classNamePrefix="select2-selection"
              name="duplicateReviewStatus"
              isMulti
              options={getDuplicateReviewOptions()}
              onChange={selected => setDuplicateReviewOptions(selected)}
              value={getDuplicateReviewOptions().filter(option => formik.values.duplicateReviewStatus.includes(option.value))}
            />
          </div>
          <div className="mt-2 mb-3">
            <Label>Billing status</Label>
            <Select
              classNamePrefix="select2-selection"
              name="billingStatus"
              options={getBillingStatusOptions()}
              onChange={selected => formik.setFieldValue("billingStatus", selected.value)}
              value={getBillingStatusOptions().find(option => option.value === formik.values.billingStatus)}
            />
          </div>
          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

export default DataTableFilters;