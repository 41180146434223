import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Card, Form, InputGroup, Button, FormGroup, UncontrolledTooltip, Alert, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "context/auth";
import { formats, formatTimestamp, isSameDate } from "helpers/dateHelper";
import { createMessage, getOrderMessages } from "store/actions";
import { bytesToSize, getBeUrl, randomStringSync, showError, showSuccess } from "helpers/utilHelper";
import { deleteMessage } from "helpers/backendHelper";
import ChatMembers from "./Members";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";
import Message from "model/message";
import EventEmitter from 'helpers/eventsHelper';
import config from "config";
import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import Templates from "./Templates";
import templateIcon from 'assets/images/chat-template-icon.svg';
import nextLineIcon from 'assets/images/next-line-icon.svg';
import useAutosizeTextArea from "hooks/useAutosizeTextArea";

const Conversation = ({ id, channelId, messages, isLoadInProgress, members, messagesError, channels }) => {

  // get user info
  const { user } = useAuth();
  const dispatch = useDispatch();

  const chatRef = useRef();
  const inputMessageRef = useRef();

  /********** STATE **********/

  const [searchMenu, setSearchMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchDirty, setIsSearchDirty] = useState(false);

  const [moreMenu, setMoreMenu] = useState(false);
  const [draftMessage, setDraftMessage] = useState("");
  const [messageBox, setMessageBox] = useState(null);
  const [showFullTimestamp, setShowFullTimestamp] = useState(false);
  const [visibleDropdowns, setVisibleDropdowns] = useState({});
  const [filesToUpload, setFilesToUpload] = useState([]);

  // get redux state from the store
  const { isSaveInProgress, saved } = useSelector(state => state.Message.Dt)

  // State for unread messages
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);

  // State for template messages carousel
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(1)
  const [isTemplatesOn, setIsTemplatesOn] = useState(true);
  const [templateMessages, setTemplateMessages] = useState([]);
  const [numberOfMultipleItems, setNumberOfMultipleItems] = useState(3);

  useAutosizeTextArea(inputMessageRef.current, draftMessage)

  /********** DROPZONE **********/

  const dropFile = useCallback(file => {
    // we will be listening for file upload progress
    // however multiple files may be uploaded in the same time
    // so we need a way to identify which file the progress report belongs to
    const uid = randomStringSync(3);
    // add file to the preview list
    setFilesToUpload(files => [...files, {
      file,
      uid,
      preview: URL.createObjectURL(file),
      progress: 100,
    }]);
    // upload file to backend
    const formData = new FormData();
    formData.append('orderId', id);
    formData.append('channel', channelId);
    formData.append('content', file);
    formData.append('uid', uid);
    dispatch(createMessage(formData));
  }, [id, channelId]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: config.CHAT_MAX_FILES,
    maxSize: config.CHAT_MAX_FILE_SIZE,
    noClick: true,
    onDropAccepted: acceptedFiles => {
      for (const file of acceptedFiles) {
        dropFile(file);
      }
    },
    onDropRejected: rejectedFiles => {
      const firstFile = rejectedFiles[0];
      const firstError = firstFile.errors[0];
      let message;
      switch (firstError.code) {
        case 'file-invalid-type':
          message = 'Please upload only images (png, jpg, jpeg)';
          break;
        case 'too-many-files':
          message = `Please select a maximum of ${config.CHAT_MAX_FILES} files`;
          break;
        case 'file-too-large':
          message = `Please upload files up to ${bytesToSize(config.CHAT_MAX_FILE_SIZE)} in size`;
          break;
        default:
          message = firstError.message;
          break;
      }
      showError(message);
    },
  });

  /********** EFFECTS **********/

  useEffect(() => {
    // listen to image upload progress
    EventEmitter.on('message.uploadProgress', uploadProgressReceived);
    const chatDropzone = chatRef.current;
    chatDropzone.addEventListener('paste', onPasteInChat);
    return () => {
      EventEmitter.off('message.uploadProgress', uploadProgressReceived);
      // it is important to NOT remove event listeners from 'chatRef.current' directly
      // user a local variable like 'chatDropzone' instead
      // because by the time this component is unmounted 'chatRef.current' will be NULL
      chatDropzone.removeEventListener('paste', onPasteInChat);
    }
  }, []);

  useEffect(() => {

    if (messages?.length) {
      const lastMessage = messages[messages.length - 1];
      if (isUserAtBottom || lastMessage.userId == user.id) {
        // user was at bottom, but after the new message, user needs to scroll down, to see the message
        // or, the current user sent a message, so he must scroll down, to see what he has written
        scrollToBottom();
      } else {
        setUnreadMessages(unreadMessages + 1);
      }
    }
  }, [messages]);

  useEffect(() => {
    // user was at bottom, but after the dots appear, user needs to scroll down, in order to see the dots
    if (isLoadInProgress && isUserAtBottom) scrollToBottom();
  }, [isLoadInProgress]);

  // runs whenever the `saved` flag changes
  useEffect(() => {
    if (saved === true) { setDraftMessage("") }
    else if (saved === false) {
      showError("Unable to send message")
    }
  }, [saved])

  // runs whenever the channel changes
  useEffect(() => {
    setDraftMessage("") // erase draft message when switching the channel
    setUnreadMessages(0); // erase unread message when switching the channel
  }, [channelId])

  // runs whenever the search term changes
  // used to detect clearing of text
  useEffect(() => {
    if (isSearchDirty && !searchTerm) {
      dispatch(getOrderMessages(id, +channelId));
      // reset dirty flag after refreshing messages
      setIsSearchDirty(false);
    }
  }, [searchTerm])

  // runs on each channel id change, we use it to know which templates should we show
  useEffect(() => {
    switch (channelId) {
      case Message.CHANNEL_SCHEDULER_DEALER:
        if (user.isScheduler()) {
          setTemplateMessages(Message.SCHEDULER_DEALER_TEMPLATES);
        } else {
          setTemplateMessages([])
        }
        break;
      case Message.CHANNEL_SCHEDULER_NOTARY:
        if (user.isScheduler()) {
          setTemplateMessages(Message.SCHEDULER_NOTARY_TEMPLATES);
        } else {
          setTemplateMessages([])
        }
        break;
      case Message.CHANNEL_INTERNAL_NOTES:
        setTemplateMessages(Message.INTERNAL_TEMPLATES)
        break;
      default:
        setTemplateMessages([]);
        break;
    }
  }, [channelId])

  // Runs on component mount to get the screen size
  useEffect(() => {
    window.addEventListener('resize', getNumberOfItemsPercentage);
    // Run just once to set the inital value
    getNumberOfItemsPercentage();
    return () => window.removeEventListener('resize', getNumberOfItemsPercentage);
  }, [])

  /********** HANDLERS **********/

  const sendMessage = () => {
    dispatch(createMessage({ orderId: id, channel: channelId, content: draftMessage }))
  }

  const toggleFullTimestamp = () => {
    setShowFullTimestamp(current => !current)
  }

  const toggleDropdown = messageId => {
    setVisibleDropdowns(prevDropdowns => ({
      ...prevDropdowns,
      [messageId]: !prevDropdowns[messageId]
    }));
  };


  const toggleSearch = () => {
    setSearchMenu(current => !current);
  }

  const toggleMore = () => {
    setMoreMenu(current => !current);
  }

  const handleSubmit = event => {
    event.preventDefault(); // prevent page refresh caused by form submission

    setIsSearchDirty(true);

    dispatch(getOrderMessages(id, +channelId, { search: searchTerm }));
  }

  const handleInputKeyDown = event => {
    if (!!draftMessage && !isSaveInProgress) {
      // Ctrl + Enter Action
      if (event.keyCode == 13 && event.ctrlKey) {
        // Get the position on the input cursor
        const position = event.target.selectionEnd;
        // Add next line from the position (where the cursor is)
        const newValue = draftMessage.substring(0, position) + '\n' + draftMessage.substring(position)
        // Move the cursor at the start of the new line created, we use setTimeout because the input renders first
        // so we need to wait a bit to set the input cursor
        setTimeout(() => {
          inputMessageRef.current.setSelectionRange(position + 1, position + 1)
        }, 0);
        // Changing the message draft
        setDraftMessage(newValue)
      }
      // Enter Action, send the message
      if (event.keyCode == 13 && !event.ctrlKey) {
        event.preventDefault();
        sendMessage();
      }
    } else {
      // Don't generate a new line if user presses enter when no message is entered
      if (event.keyCode == 13) {
        event.preventDefault();
      }
    }
  }

  // Handle when user clicks on next line icon
  const handleNextLine = () => {
    // Focus on the input
    inputMessageRef.current.focus();
    // Get the position on the input cursor
    const position = inputMessageRef.current.selectionEnd;
    // Add next line from the position (where the cursor is)
    const newValue = draftMessage.substring(0, position) + '\n' + draftMessage.substring(position)
    // Move the cursor at the start of the new line created, we use setTimeout because the input renders first
    // so we need to wait a bit to set the input cursor
    setTimeout(() => {
      inputMessageRef.current.setSelectionRange(position + 1, position + 1)
    }, 0);
    // Changing the message draft
    setDraftMessage(newValue)
  }

  // Handler for delete message
  const handleDeleteMessage = messageId => {
    deleteMessage(messageId)
      .then(() => {
        showSuccess(`The message was deleted`);
        dispatch(getOrderMessages(id, +channelId, { search: searchTerm }));
      })
      .catch(ex => {
        showError('Unable to delete message');
      })
      .finally(() => {
        setVisibleDropdowns(true);
      });
  };

  // Handle for moving through the templates list (next carousel)
  const handleNextTemplate = () => {
    setCurrentTemplateIndex(prevState => prevState + 1)
  }

  // Handle for moving through the templates list (previous carousel)
  const handlePreviousTemplate = () => {
    setCurrentTemplateIndex(prevState => prevState - 1)
  }

  // Handle when user clicks on a template (it populates the input)
  const handleTemplateItemClick = (itemText) => {
    setDraftMessage(itemText)
  }

  // Handle when user clicks on toggle templates (show/hide templates)
  const handleToggleTemplates = () => {
    setIsTemplatesOn(prev => !prev)
  }

  // Function that sets the percentage for multiple items (33.3 means 3 per page)
  const getNumberOfItemsPercentage = () => {
    const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if (width > 1700) {
      setNumberOfMultipleItems(3);
      setCurrentTemplateIndex(1);
    }
    if (width < 1700) {
      setNumberOfMultipleItems(2);
      setCurrentTemplateIndex(1);
    };
    if (width < 1300) {
      setNumberOfMultipleItems(1);
      setCurrentTemplateIndex(1);
    };
  }

  // Function that determines if the next button on templates is disabled or not
  const nextButtonIsDisabled = () => {
    if (numberOfMultipleItems === 1) {
      return currentTemplateIndex === templateMessages.length - 1
    }
    if (numberOfMultipleItems === 2) {
      return currentTemplateIndex > templateMessages.length - 2
    }
    if (numberOfMultipleItems === 3) {
      return currentTemplateIndex > templateMessages.length - 3
    }
    return false;
  }

  // Function that determines if the previous button on templates is disabled or not
  const previousButtonIsDisabled = () => {
    if (numberOfMultipleItems === 1) {
      return currentTemplateIndex === 0
    }
    if (numberOfMultipleItems === 2) {
      return currentTemplateIndex < 1
    }
    if (numberOfMultipleItems === 3) {
      return currentTemplateIndex < 2
    }
    return false;
  }

  /********** OTHER **********/
  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
      setIsUserAtBottom(true);
      setUnreadMessages(0);
    }
  };

  const handleScroll = () => {
    if (messageBox) {
      const isBottom = messageBox.scrollHeight - messageBox.scrollTop === messageBox.clientHeight;
      setIsUserAtBottom(isBottom);
      if (isBottom) {
        setUnreadMessages(0);
      }
    }
  };

  const getMessageImageUrl = messageId => getBeUrl(`message/${messageId}/image.png`);

  const getMessageThumbnailUrl = messageId => getBeUrl(`message/${messageId}/thumbnail.png`);

  const getGalleryItemContent = messageId => (<img src={getMessageImageUrl(messageId)} className="gallery-preview-img" />)

  const uploadProgressReceived = useCallback(data => {
    // see for which file this progress report is
    const uid = data.fileUid;
    const progress = 100 - Math.round(data.ev.loaded / data.ev.total * 100);
    setFilesToUpload(files => {
      if (progress == 0) {
        // upload is finished so remove the file from the preview queue
        return files.filter(f => f.uid != uid);
      } else {
        return files.map(f => {
          if (f.uid == uid) {
            // update the progress of the file
            f.progress = progress;
          }
          return f;
        });
      }
    });
  }, []);

  const onPasteInChat = useCallback(e => {
    const acceptedRegx = /^image\/(jpe?g|png)$/i;
    for (const item of e.clipboardData.items) {
      if (acceptedRegx.test(item.type)) {
        dropFile(item.getAsFile());
      }
    }
  }, [dropFile]);

  return (
    <Card className="w-100 h-100">
      <div className="p-4 border-bottom ">
        <Row className="align-items-center">
          <Col xs="9">
            <h5 className="font-size-15 mb-1 d-inline-block">
              {channels[channelId]}
            </h5>
            <ChatMembers members={members} />
          </Col>
          <Col xs="3">
            <ul className="list-inline user-chat-nav text-end mb-0">
              <li className="list-inline-item d-none d-sm-inline-block">
                <Dropdown
                  isOpen={searchMenu}
                  toggle={toggleSearch}
                  direction="start"
                >
                  <DropdownToggle className="btn nav-btn" tag="i">
                    <i className="bx bx-search-alt-2" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-md"
                  >
                    <Form className="p-3" onSubmit={handleSubmit}>
                      <FormGroup className="m-0">
                        <InputGroup>
                          <Input
                            type="search"
                            className="form-control"
                            placeholder="Search ..."
                            aria-label="Search in chat"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                          />
                          <Button color="primary" type="submit">
                            <i className="mdi mdi-magnify" />
                          </Button>
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  </DropdownMenu>
                </Dropdown>
              </li>
              <li className="list-inline-item">
                <Dropdown
                  isOpen={moreMenu}
                  toggle={toggleMore}
                >
                  <DropdownToggle className="btn nav-btn" tag="i">
                    <i className="bx bx-dots-horizontal-rounded" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem onClick={toggleFullTimestamp}>
                      {(showFullTimestamp ? 'Hide' : 'Show') + ' full timestamp'}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </li>
            </ul>
          </Col>
        </Row>
      </div>

      <div {...getRootProps({ className: 'chat-dropzone' })}>
        <div ref={chatRef}>
          {messagesError && <Alert color="danger" className="fade show text-center mb-4">
            <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load messages
          </Alert>}

          {!messagesError && <div className="chat-conversation p-3">
            {unreadMessages > 0 && (<button onClick={scrollToBottom} className="btn btn-link unread-messages-btn">
              + {unreadMessages} unread message{unreadMessages > 1 ? 's' : ''}
            </button>
            )}
            {isLoadInProgress && !messages.length && <div className="chat-item dot-flashing mx-auto" />}
            <Gallery options={{ mainClass: 'chat-gallery', bgClickAction: 'close' }}>
              <PerfectScrollbar
                style={{ height: "470px", touchAction: "none" }}
                containerRef={ref => setMessageBox(ref)}
                onScrollY={handleScroll}
                options={{ suppressScrollX: true }}
              >
                {!isLoadInProgress && !messages.length && <div className="text-center">
                  <span className="text-muted">{isSearchDirty ? "No messages found" : "Write a message to start the conversation"}</span>
                </div>}
                {messages.map((message, index) => {

                  const dateLabel = isSameDate(message.createdTs) ? 'Today' : formatTimestamp(message.createdTs, formats.CHAT_DATE)

                  return (<React.Fragment key={message.id}>
                    {index === 0 && (
                      <div className="chat-day-title chat-item">
                        <span className="title">{dateLabel}</span>
                      </div>
                    )}
                    {index > 0 && !isSameDate(message.createdTs, messages[index - 1]?.createdTs) && (
                      <div className="chat-day-title chat-item">
                        <span className="title">{dateLabel}</span>
                      </div>
                    )}
                    <div className={classnames("chat-item", message.userId === user.id ? "right" : "")}>
                      <div className="conversation-list list-inline-item">
                        {message.userId === user.id && (
                          <Dropdown
                            isOpen={visibleDropdowns[message.id] || false}
                            toggle={() => toggleDropdown(message.id)}
                          >
                            <DropdownToggle className="btn nav-btn" tag="i">
                              <i className="mdi mdi-dots-vertical message-dots-icon" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end dropdown-delete-message">
                              <DropdownItem onClick={() => handleDeleteMessage(message.id)}>
                                <i className="mdi mdi-delete-outline" /> <span>Delete Message</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )}

                        <div className="ctext-wrap">
                          <div className="conversation-name">
                            {message.senderName}
                          </div>
                          {message.contentType == Message.CONTENT_TYPE_IMAGE && <Item content={getGalleryItemContent(message.id)}>
                            {({ ref, open }) => (<img src={getMessageThumbnailUrl(message.id)} className="conversation-content-image mt-1 mb-3" onLoad={scrollToBottom} ref={ref} onClick={open} />)}
                          </Item>}
                          {message.contentType == Message.CONTENT_TYPE_TEXT && <p className="message-content">{message.content}</p>}
                          <div className="chat-time mb-0">
                            <div style={{ cursor: 'pointer' }} onClick={toggleFullTimestamp}>
                              <i className="bx bx-time-five align-middle me-1" />
                              {formatTimestamp(message.createdTs, showFullTimestamp ? formats.DATETIME : formats.HOUR)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                  )
                })}
                {isLoadInProgress && !!messages.length && <div className="dot-flashing mx-auto chat-item" />}
              </PerfectScrollbar>
            </Gallery>
          </div>}

          {!!filesToUpload.length && <div className="dz-preview p-3 pt-0">
            {filesToUpload.map(entry => {
              return <div key={entry.uid} className="dz-preview-item">
                <img className="dz-preview-img" src={entry.preview} onLoad={() => { URL.revokeObjectURL(entry.preview) }} />
                <div className="dz-upload-progress" style={{ width: `${entry.progress}%` }}></div>
                <i className="mdi mdi-spin mdi-loading dz-preview-loader" />
              </div>
            })}
          </div>}

          <div className="p-3 chat-input-section">
            {
              !!templateMessages.length &&
              <Collapse isOpen={isTemplatesOn}>
                <div className={classnames("mb-3 mt-1", { "ms-3": numberOfMultipleItems > 1 })}>
                  {/* Chat Templates */}
                  <Templates
                    templateMessages={templateMessages}
                    activeIndex={currentTemplateIndex}
                    onItemClick={handleTemplateItemClick}
                    numberOfMultipleItems={numberOfMultipleItems}
                    onItemChange={(index) => setCurrentTemplateIndex(index)}
                  />
                </div>
              </Collapse>
            }
            {/* Action Bar */}
            <Row>
              <Col>
                <div className="mb-3 d-flex justify-content-between">
                  <div className="ms-3">
                    <img id='next-line-icon' onClick={handleNextLine} src={nextLineIcon} className="me-4" />
                    <UncontrolledTooltip placement="top" target='next-line-icon'>Press Ctrl + Enter to move to the next line</UncontrolledTooltip>
                    {
                      !!templateMessages.length &&
                      <>
                        <img id='template-icon' onClick={handleToggleTemplates} src={templateIcon} className="me-4" />
                        <UncontrolledTooltip placement="top" target='template-icon'>{isTemplatesOn ? "Hide templates" : "Display templates"}</UncontrolledTooltip>
                      </>
                    }
                  </div>
                  {
                    isTemplatesOn && !!templateMessages.length &&
                    <div className="d-flex">
                      <button
                        className="btn template-action-button me-2"
                        tag="button"
                        onClick={handlePreviousTemplate}
                        disabled={previousButtonIsDisabled()}
                      >
                        <i className='bx bxs-left-arrow text-primary template-action-icon' ></i>
                      </button>
                      <button
                        className="btn template-action-button"
                        tag="button"
                        onClick={handleNextTemplate}
                        disabled={nextButtonIsDisabled()}
                      >
                        <i className='bx bxs-right-arrow text-primary template-action-icon' ></i>
                      </button>
                    </div>
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="position-relative">
                  <textarea
                    type="text"
                    value={draftMessage}
                    ref={inputMessageRef}
                    onChange={e => setDraftMessage(e.target.value)}
                    onKeyDown={handleInputKeyDown}
                    className="form-control chat-input"
                    placeholder="Enter Message..."
                    rows={1}
                  />
                  <div className="chat-input-links">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <Button color="default" size="sm" onClick={open}>
                          <i className="mdi mdi-file-image-outline" id="Filetooltip" />
                          <UncontrolledTooltip placement="top" target="Filetooltip">Add Images</UncontrolledTooltip>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col className="col-auto">
                <Button
                  type="button"
                  color="primary"
                  onClick={sendMessage}
                  disabled={!draftMessage || isSaveInProgress}
                  className="btn btn-primary btn-rounded chat-send w-md "
                >
                  <span className="d-none d-sm-inline-block me-2">
                    Send
                  </span>
                  {isSaveInProgress ? <i className="mdi mdi-spin mdi-loading ms-1" /> : <i className="mdi mdi-send" />}
                </Button>
              </Col>
            </Row>
          </div>

          <div className={classnames('dropzone m-1', { 'is-drag-active': isDragActive })}>
            <div className="dz-message needsclick" onClick={open}>
              <input {...getInputProps()} />
              <div className="dz-message-text">
                <i className="display-4 dz-message-icon text-muted bx bxs-cloud-upload me-2" />
                <h4>Drop images here</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

Conversation.propTypes = {
  id: PropTypes.number,
  channelId: PropTypes.number,
  messages: PropTypes.array,
  isLoadInProgress: PropTypes.bool,
  members: PropTypes.array,
  messagesError: PropTypes.object,
  channels: PropTypes.object,
}

export default Conversation;