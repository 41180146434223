import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { doOrderSingleCleanup, getFullOrder } from "store/actions";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import Col from "components/Shared/Col";
import SectionCustomer from "../Partial/Section/Customer";
import SectionOrderInfo from "../Partial/Section/OrderInfo";
import SectionIdentityVerification from "../Partial/Section/IdentityVerification";
import SectionESignedDocs from "../Partial/Section/ESignedDocs";
import SectionInkSignedDocs from "../Partial/Section/InkSignedDocs";
// import SectionShippingInfo from "../Partial/Section/ShippingInfo";
import SectionFinanceDocs from "../Partial/Section/FinanceDocs";
import SectionActivity from "../Partial/Section/Activity";
import SectionMessages from "../Partial/Section/Messages";
import SectionSealInfo from "../Partial/Section/SealInfo";
import SectionNotaryInfo from "../Partial/Section/NotaryInfo";
import SealBadge from "../Partial/Seal/Badge";
import SectionVehicle from "../Partial/Section/Vehicle";
import Order from "model/order";
import { route, routes } from "helpers/routeHelper";
import { useSocketOn, useSubscribeToOrder } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import { orderIsLocked, showError } from "helpers/utilHelper";
import SectionAdditionalFee from "../Partial/Section/AdditionalFee";
import SectionScheduler from "../Partial/Section/Scheduler";
import SectionSupportingDocs from "../Partial/Section/SupportingDocs";
import SectionContract from "../Partial/Section/Contract";
import SectionAccounting from "../Partial/Section/Accounting/Accounting";
import SectionOrderActions from "../Partial/Section/OrderActions/Actions";
import SectionOrderHeader from "../Partial/Section/OrderActions/Header";
import SectionInactiveMessage from "../Partial/Section/InactiveStatus";
import SectionInternalStatus from "../Partial/Section/InternalStatus";
import { doOrderFeesCleanup, getOrderFees } from "../../../store/orderFees/actions";
import SectionTrackingAndShipping from "../Partial/Section/TrackingAndShipping";
import SpecialInstructionsLabel from "../Partial/View/SpecialInstructionsLabel";
import DealerGroup from "model/dealerGroup";
import Confirmation from 'components/Shared/Confirmation';
import { muteAllChannels, getChannels, unmuteAllChannels } from "helpers/backendHelper";
import Message from "model/message";

const ViewIndex = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [muteAllVisible, setMuteAllVisible] = useState(false);
  const [channelsList, setChannelsList] = useState();

  const isAllMuted = channelsList?.filter(channel => channel.num !== Message.CHANNEL_MY_NOTES).every(channel => channel.isMuted);

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);
  const { fees, isLoadInProgress: feesInProgress } = useSelector(state => state.OrderFees.Single);

  // Calculate the time difference in hours
  const expiredTime = Math.floor((Date.now() - order?.updatedTs * 1000) / 3600000);

  const isGroupSpecialInstructionsAppliesTo = () => order?.dealerGroupSpecialInstructionsAppliesTo?.some(item => item == DealerGroup.SPECIAL_INTSTRUCTIONS_APPLY_TO_ADMIN);
  const showGroupSpecialInstructions = () => order?.dealerGroupSpecialInstructions && isGroupSpecialInstructionsAppliesTo();

  // Mute/Unmute all chanels modal - icon and text
  const muteAllIcon = <div className="mute-icon"><i className={classnames('bx bx-bell', { 'bx-bell-off': isAllMuted })} /></div>;
  const muteAllDescription = isAllMuted ?
    'Unmuting the messages will notify you about messages sent on this order.' :
    'Muting the messages will no longer notify you about messages sent on this order.'

  /********** EFFECTS **********/

  // runs once on component mount
  // get order data
  useEffect(() => {
    refreshOrder();
    return () => {
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  // get order fees data
  useEffect(() => {
    refreshOrderFees();
    return () => {
      dispatch(doOrderFeesCleanup());
    }
  }, [id]);

  // get updated channels list (with mute/unmute status)
  useEffect(() => {
    refreshChannelsList();
  }, []);

  /********** OTHER **********/

  const refreshOrder = useCallback(() => dispatch(getFullOrder(id)), [id]);
  const refreshOrderFees = useCallback(() => dispatch(getOrderFees(id)), [id]);
  const refreshOrderAdditionalVehicle = () => {
    dispatch(getFullOrder(id));
    dispatch(getOrderFees(id));
  }

  const refreshChannelsList = () => {
    getChannels(id)
      .then(resp => setChannelsList(resp.channels))
      .catch(err => showError("Unable to load channels data."))
  }

  // Mute All Order Channels
  const handleMuteAll = () => {
    muteAllChannels(id)
      .then(resp => {
        setMuteAllVisible(false);
        refreshChannelsList();
      })
      .catch(err => showError("Unable to mute all messages."))
  }

  // Unmute All Order Channels
  const handleUnmuteAll = () => {
    unmuteAllChannels(id)
      .then(resp => {
        setMuteAllVisible(false);
        refreshChannelsList();
      })
      .catch(err => showError("Unable to unmute all messages."))
  }

  /********** SOCKET **********/

  // start receiving updates about this particular document
  useSubscribeToOrder(order?.id);

  const onOrderChanged = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple orders
    // therefore we need to check whether this update refers to the right order
    if (data.id == order.id) {
      refreshOrder();
    }
  }, [order?.id, refreshOrder]);

  const condition = useCallback(() => !!order?.id, [order?.id]);

  // listen for changes on order documents
  useSocketOn(socketEvent.orderChanged, onOrderChanged, condition);

  /********** OTHER **********/

  const isLocked = () => orderIsLocked(order.sealStatus);

  const isNotDraft = () => order.status !== Order.STATUS_DRAFT;

  const isReadyForSealing = () => order.status == Order.STATUS_COMPLETED;

  const isQueuedForSealing = () => order.sealStatus == Order.SEAL_STATUS_QUEUED;

  const isSealed = () => order.sealStatus == Order.SEAL_STATUS_SEALED;

  const sealFailed = () => order.sealStatus == Order.SEAL_STATUS_FAILED;

  const canViewSeal = () => isReadyForSealing() || isQueuedForSealing() || sealFailed() || isSealed();

  const isShipping = () => order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING;

  const isRemoteOrder = () => order.customerLocation === Order.CUSTOMER_LOCATION_REMOTE;


  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Row>
            <Col>
              <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
              {canViewSeal() && <div className="d-flex align-items-center">
                <button className="mute-btn position-relative" onClick={() => setMuteAllVisible(true)}>
                  <i className={classnames('bx bx-bell', { 'bx-bell-off': isAllMuted })} />
                  <div className="mute-message">{isAllMuted ? 'Unmute' : 'Mute'} all channels</div>
                </button>
                {showGroupSpecialInstructions() && <SpecialInstructionsLabel className="me-3" instructions={order.dealerGroupSpecialInstructions} />}
              </div>}
            </Col>

            {!canViewSeal() && <Col xxl="4" className='text-end d-flex align-items-center justify-content-end mb-3 col-xxl-4'>
              <button className="mute-btn position-relative" onClick={() => setMuteAllVisible(true)}>
                <i className={classnames('bx bx-bell', { 'bx-bell-off': isAllMuted })} />
                <div className="mute-message">{isAllMuted ? 'Unmute' : 'Mute'} all channels</div>
              </button>
              {showGroupSpecialInstructions() && <SpecialInstructionsLabel className="ms-3" instructions={order.dealerGroupSpecialInstructions} />}
            </Col>}
            <SealBadge order={order} />
          </Row>
          {order.isInactive && order.status != Order.STATUS_CANCELLED && < Row >
            <Col>
              <SectionInactiveMessage time={expiredTime} />
            </Col>
          </Row>}
          {iAmGranted(perms.view_order_internal_status) && !!order.internalStatus && <Row>
            <Col>
              <SectionInternalStatus text={order.escalationReason} />
            </Col>
          </Row>}
          <Row>
            <Col>
              <SectionOrderHeader order={order} id={id} />
            </Col>
          </Row>
          <Row>
            <Col>
              <SectionOrderActions order={order} id={id} refreshHandler={refreshOrderFees} />
            </Col>
          </Row>
          {/* {iAmGranted(perms.view_similar_orders) && <Row>
            <Col>
              <SectionDuplicateOrders id={id} />
            </Col>
          </Row>} */}
          <div>
            <Row>
              <Col xxl="6">
                <SectionCustomer id={id} order={order} refreshHandler={refreshOrder} isLoadBusy={isLoadInProgress} isLocked={isLocked()} />
              </Col>
              <Col xxl="6">
                {isRemoteOrder() && <Col xxl="12">
                  <SectionVehicle id={id} order={order} refreshHandler={refreshOrderAdditionalVehicle} isLoadBusy={isLoadInProgress} isLocked={isLocked()} />
                </Col>}
                {isShipping() && <Col xxl="12">
                  <SectionTrackingAndShipping id={id} order={order} refreshHandler={refreshOrder} isLoadBusy={isLoadInProgress} isLocked={isLocked()} />
                </Col>}
                {order.isRushRequired && <Col xxl="12">
                  <SectionAdditionalFee order={order} />
                </Col>}
              </Col>
              <Col xxl="6">
                <SectionOrderInfo refreshHandler={refreshOrder} order={order} />
              </Col>
              {order.isNotaryRequired &&
                <Col xxl="6">
                  <SectionContract id={id} order={order} refreshHandler={refreshOrder} isLoadBusy={isLoadInProgress} isLocked={isLocked()} />
                </Col>
              }
            </Row>
            <Row>
              {order.isVidRequired && <Col xxl="3">
                <SectionIdentityVerification id={id} order={order} />
              </Col>}

              {order.isEsignRequired && <Col xxl="3">
                <SectionESignedDocs id={id} />
              </Col>}

              {order.isInkSignRequired && <Col xxl="3">
                <SectionInkSignedDocs id={id} />
              </Col>}

              {(order.isEsignRequired || order.isInkSignRequired) && <>
                <Col xxl="3">
                  <SectionFinanceDocs id={id} />
                </Col>
                <Col xxl="3">
                  <SectionSupportingDocs id={id} />
                </Col>
              </>}

              {order.isNotaryRequired && <Col xxl="3">
                <SectionScheduler order={order} />
              </Col>}
              {order.isNotaryRequired && isNotDraft() && <Col xxl="3">
                <SectionNotaryInfo order={order} />
              </Col>}
              <Col xxl="3">
                <SectionActivity id={id} />
              </Col>
              {isRemoteOrder() && <Col xxl="3">
                <SectionMessages id={id} />
              </Col>}
              {canViewSeal() && <Col xxl="3">
                <SectionSealInfo order={order} isLocked={isLocked()} />
              </Col>}
              {iAmGranted(perms.view_order_accounting) && (
                <Row>
                  <Col xxl="6">
                    <SectionAccounting id={id} fees={fees} isLoadInProgress={feesInProgress} isNotaryRequired={order.isNotaryRequired} refreshHandler={refreshOrderFees} />
                  </Col>
                </Row>
              )}
              <Col className="text-end align-self-end">
                <div className="mb-4">
                  <Link to={route(routes.list_orders)} className="btn btn-primary btn-faded mt-3">Return to orders view</Link>
                </div>
              </Col>
            </Row>
          </div>

          {/* Mute|Unmute All Channels - Modal */}
          {muteAllVisible && <Confirmation
            style={{ backgroundColor: 'white', width: 330 }}
            customIcon={muteAllIcon}
            confirmBtnText="Confirm"
            onConfirm={() => isAllMuted ? handleUnmuteAll() : handleMuteAll()}
            onCancel={() => setMuteAllVisible(false)}
            closeOnClickOutside={false}
            reverseButtons={false}
          >
            <div className="d-flex justify-content-center">
              <Col sm={9}>
                <span style={{ color: '#556EE6', fontSize: 15, textAlign: 'left' }}>{muteAllDescription}</span>
              </Col>
            </div>
          </Confirmation>}
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>
    }
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </React.Fragment >
}

const breadcrumbs = order => [{
  title: `${order.signers[0]?.fullName}`,
}, {
  title: `#${order.id}`,
}];

export default ViewIndex;
