import {
  GET_ORDER_DT,
  GET_ORDER_DT_OK,
  GET_ORDER_DT_ERR,
  DO_ORDER_DT_CLEANUP,

  APPLY_ORDER_DT_FILTERS,
  CLEAR_ORDER_DT_FILTERS,
  PATCH_ORDER_DT_FILTERS,
  UNSET_ORDER_DT_FILTERS,
  DO_ORDER_DT_FILTERS_CLEANUP,

  GET_INACTIVE_ORDER_LIST,
  GET_ORDER_LIST_OK,
  GET_ORDER_LIST_ERR,
  DO_ORDER_LIST_CLEANUP,

  GET_ORDER,
  GET_ORDER_WITH_CUSTOMER_SIGNERS,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES,
  GET_FULL_ORDER,
  GET_ORDER_OK,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_OK,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES_OK,
  GET_FULL_ORDER_OK,
  GET_ORDER_ERR,
  DELETE_ORDER,
  DELETE_ORDER_OK,
  DELETE_ORDER_ERR,
  DO_ORDER_SINGLE_CLEANUP,
  RESET_ORDER_ACTION_FLAG,

  RESEND_CUSTOMER_NOTIFICATIONS,
  RESEND_CUSTOMER_NOTIFICATIONS_OK,
  RESEND_CUSTOMER_NOTIFICATIONS_ERR,

  START_CUSTOMER_MEETING,
  START_CUSTOMER_MEETING_OK,
  START_CUSTOMER_MEETING_ERR,
  START_NOTARY_MEETING,
  START_NOTARY_MEETING_OK,
  START_NOTARY_MEETING_ERR,

  GET_ORDER_VID,
  GET_ORDER_VID_OK,
  GET_ORDER_VID_ERR,
  DO_ORDER_VID_CLEANUP,

  GET_ORDER_MESSAGES,
  GET_ORDER_MESSAGES_OK,
  GET_ORDER_MESSAGES_ERR,
  DO_ORDER_MESSAGES_CLEANUP,
  GET_CHAT_MEMBERS,
  GET_CHAT_MEMBERS_OK,
  GET_CHAT_MEMBERS_ERR,

  GET_ORDER_ACTIVITY,
  GET_ORDER_ACTIVITY_OK,
  GET_ORDER_ACTIVITY_ERR,
  DO_ORDER_ACTIVITY_CLEANUP,

  GET_ORDER_INK_SIGN_DOCS,
  GET_ORDER_INK_SIGN_DOCS_OK,
  GET_ORDER_INK_SIGN_DOCS_ERR,
  DO_ORDER_INK_SIGN_DOCS_CLEANUP,

  GET_ORDER_E_SIGN_DOCS,
  GET_ORDER_E_SIGN_DOCS_OK,
  GET_ORDER_E_SIGN_DOCS_ERR,
  DO_ORDER_E_SIGN_DOCS_CLEANUP,

  ASSIGN_ORDER_SCHEDULER,
  ASSIGN_ORDER_SCHEDULER_OK,
  ASSIGN_ORDER_SCHEDULER_ERR,
  DO_ORDER_SCHEDULER_ASSIGNMENT_CLEANUP,

  AWARD_NOTARY_BID,
  AWARD_NOTARY_BID_OK,
  AWARD_NOTARY_BID_ERR,

  DECLINE_NOTARY_BID,
  DECLINE_NOTARY_BID_OK,
  DECLINE_NOTARY_BID_ERR,

  NOTIFY_NOTARY_BID,
  NOTIFY_NOTARY_BID_OK,
  NOTIFY_NOTARY_BID_ERR,

  GET_ORDER_NOTARY_BID_DT,
  GET_ORDER_NOTARY_BID_DT_OK,
  GET_ORDER_NOTARY_BID_DT_ERR,
  DO_ORDER_NOTARY_BID_DT_CLEANUP,

  START_ORDER_NOTARY_BID,
  START_ORDER_NOTARY_BID_OK,
  START_ORDER_NOTARY_BID_ERR,

  ADD_ORDER_NOTARY_BID,
  ADD_ORDER_NOTARY_BID_OK,
  ADD_ORDER_NOTARY_BID_ERR,

  DO_NOTARY_BID_CLEANUP,

  GET_ORDER_NOTARY_DT,
  GET_ORDER_NOTARY_DT_OK,
  GET_ORDER_NOTARY_DT_ERR,
  DO_ORDER_NOTARY_DT_CLEANUP,

  APPLY_ORDER_NOTARY_DT_FILTERS,
  CLEAR_ORDER_NOTARY_DT_FILTERS,
  PATCH_ORDER_NOTARY_DT_FILTERS,
  UNSET_ORDER_NOTARY_DT_FILTERS,

  DO_ORDER_NOTARY_DT_FILTERS_CLEANUP,

  START_NOTARY_REQUEUE,
  START_NOTARY_REQUEUE_ERROR,
  START_NOTARY_REQUEUE_OK,

  UPDATE_ORDER_CUSTOMER,
  UPDATE_ORDER_VEHICLE,
  UPDATE_ORDER_CONTRACT,
  SAVE_ORDER_OK,
  SAVE_ORDER_ERR,
  DO_ORDER_FORM_CLEANUP,

  GET_ORDER_SUPPORTING_DOCS,
  GET_ORDER_SUPPORTING_DOCS_OK,
  GET_ORDER_SUPPORTING_DOCS_ERR,
  DO_ORDER_SUPPORTING_DOCS_CLEANUP,
  GET_ORDER_PREVIEW_DOCS,
  GET_ORDER_PREVIEW_DOCS_OK,
  GET_ORDER_PREVIEW_DOCS_ERR,
  DO_ORDER_PREVIEW_DOCS_CLEANUP,
  SET_ORDER_IS_RURAL,

  GET_NOTARY_ORDER_DT,
  GET_NOTARY_ORDER_DT_OK,
  GET_NOTARY_ORDER_DT_ERR,
  DO_NOTARY_ORDER_DT_CLEANUP,

} from './actionTypes';

/********** DATATABLE **********/

export const getOrderDt = params => ({
  type: GET_ORDER_DT,
  payload: { params },
});

export const getOrderDtOk = response => ({
  type: GET_ORDER_DT_OK,
  payload: { response },
});

export const getOrderDtErr = error => ({
  type: GET_ORDER_DT_ERR,
  payload: { error },
});

export const doOrderDtCleanup = () => ({
  type: DO_ORDER_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyOrderDtFilters = filters => ({
  type: APPLY_ORDER_DT_FILTERS,
  payload: { filters },
});

export const patchOrderDtFilters = filters => ({
  type: PATCH_ORDER_DT_FILTERS,
  payload: { filters },
});

export const clearOrderDtFilters = () => ({
  type: CLEAR_ORDER_DT_FILTERS,
});

export const unsetOrderDtFilters = () => ({
  type: UNSET_ORDER_DT_FILTERS,
});

export const doOrderDtFiltersCleanup = () => ({
  type: DO_ORDER_DT_FILTERS_CLEANUP,
});

/********** LIST **********/

export const getInactiveOrderList = () => ({
  type: GET_INACTIVE_ORDER_LIST,
})

export const getOrderListOk = response => ({
  type: GET_ORDER_LIST_OK,
  payload: { response },
})

export const getOrderListErr = error => ({
  type: GET_ORDER_LIST_ERR,
  payload: { error },
});

export const doOrderListCleanup = () => ({
  type: DO_ORDER_LIST_CLEANUP,
});

/********** SINGLE **********/

export const getOrder = id => ({
  type: GET_ORDER,
  payload: { id },
});

export const getOrderWithCustomerSigners = id => ({
  type: GET_ORDER_WITH_CUSTOMER_SIGNERS,
  payload: { id },
});

export const getOrderWithCustomerSignersAndNotaries = id => ({
  type: GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES,
  payload: { id },
});

export const getFullOrder = id => ({
  type: GET_FULL_ORDER,
  payload: { id },
});

export const getOrderOk = response => ({
  type: GET_ORDER_OK,
  payload: { response },
});

export const getOrderWithCustomerSignersOk = (orderResponse, signersResponse) => ({
  type: GET_ORDER_WITH_CUSTOMER_SIGNERS_OK,
  payload: { orderResponse, signersResponse },
});

export const getOrderWithCustomerSignersAndNotariesOk = (orderResponse, signersResponse, notariesResponse) => ({
  type: GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES_OK,
  payload: { orderResponse, signersResponse, notariesResponse },
});

export const getFullOrderOk = (orderResponse, signersResponse, vehiclesResponse, notariesResponse) => ({
  type: GET_FULL_ORDER_OK,
  payload: { orderResponse, signersResponse, vehiclesResponse, notariesResponse },
});

export const getOrderErr = error => ({
  type: GET_ORDER_ERR,
  payload: { error },
});

export const deleteOrder = id => ({
  type: DELETE_ORDER,
  payload: { id },
});

export const deleteOrderOk = response => ({
  type: DELETE_ORDER_OK,
  payload: { response },
});

export const deleteOrderErr = error => ({
  type: DELETE_ORDER_ERR,
  payload: { error },
});

export const doOrderSingleCleanup = () => ({
  type: DO_ORDER_SINGLE_CLEANUP,
});

export const resetOrderActionFlag = flag => ({
  type: RESET_ORDER_ACTION_FLAG,
  payload: { flag },
});

export const resendCustomerNotifications = id => ({
  type: RESEND_CUSTOMER_NOTIFICATIONS,
  payload: { id },
});

export const resendCustomerNotificationsOk = response => ({
  type: RESEND_CUSTOMER_NOTIFICATIONS_OK,
  payload: { response },
});

export const resendCustomerNotificationsErr = error => ({
  type: RESEND_CUSTOMER_NOTIFICATIONS_ERR,
  payload: { error },
});

export const startCustomerMeeting = orderId => ({
  type: START_CUSTOMER_MEETING,
  payload: { orderId },
});

export const startCustomerMeetingOk = response => ({
  type: START_CUSTOMER_MEETING_OK,
  payload: { response },
});

export const startCustomerMeetingErr = error => ({
  type: START_CUSTOMER_MEETING_ERR,
  payload: { error },
});

export const startNotaryMeeting = orderId => ({
  type: START_NOTARY_MEETING,
  payload: { orderId },
});

export const startNotaryMeetingOk = response => ({
  type: START_NOTARY_MEETING_OK,
  payload: { response },
});

export const startNotaryMeetingErr = error => ({
  type: START_NOTARY_MEETING_ERR,
  payload: { error },
});

/********** FORM **********/

export const updateOrderCustomer = (data, id) => ({
  type: UPDATE_ORDER_CUSTOMER,
  payload: { data, id },
});

export const updateOrderVehicle = (data, id) => ({
  type: UPDATE_ORDER_VEHICLE,
  payload: { data, id },
});

export const updateOrderContract = (data, id) => ({
  type: UPDATE_ORDER_CONTRACT,
  payload: { data, id },
});

export const saveOrderOk = response => ({
  type: SAVE_ORDER_OK,
  payload: { response },
});

export const saveOrderErr = error => ({
  type: SAVE_ORDER_ERR,
  payload: { error },
});

export const doOrderFormCleanup = () => ({
  type: DO_ORDER_FORM_CLEANUP,
});

export const setIsRural = (id, isRural) => ({
  type: SET_ORDER_IS_RURAL,
  payload: { id, isRural },
});

/********** VID **********/

export const getOrderVid = (id, signer) => ({
  type: GET_ORDER_VID,
  payload: { id, signer },
});

export const getOrderVidOk = (response, signer) => ({
  type: GET_ORDER_VID_OK,
  payload: { response, signer },
});

export const getOrderVidErr = (error, signer) => ({
  type: GET_ORDER_VID_ERR,
  payload: { error, signer },
});

export const doOrderVidCleanup = signer => ({
  type: DO_ORDER_VID_CLEANUP,
  payload: { signer },
});

/********** INK-SIGN DOCS **************/

export const getOrderInkSignDocs = id => ({
  type: GET_ORDER_INK_SIGN_DOCS,
  payload: { id },
});

export const getOrderInkSignDocsOk = response => ({
  type: GET_ORDER_INK_SIGN_DOCS_OK,
  payload: { response },
});

export const getOrderInkSignDocsErr = error => ({
  type: GET_ORDER_INK_SIGN_DOCS_ERR,
  payload: { error },
});

export const doOrderInkSignDocsCleanup = () => ({
  type: DO_ORDER_INK_SIGN_DOCS_CLEANUP,
});

/********** E-SIGN DOCS **************/

export const getOrderESignDocs = id => ({
  type: GET_ORDER_E_SIGN_DOCS,
  payload: { id },
});

export const getOrderESignDocsOk = response => ({
  type: GET_ORDER_E_SIGN_DOCS_OK,
  payload: { response },
});

export const getOrderESignDocsErr = error => ({
  type: GET_ORDER_E_SIGN_DOCS_ERR,
  payload: { error },
});

export const doOrderESignDocsCleanup = () => ({
  type: DO_ORDER_E_SIGN_DOCS_CLEANUP,
});

/********** SUPPORTING DOCS **************/

export const getOrderSupportingDocs = id => ({
  type: GET_ORDER_SUPPORTING_DOCS,
  payload: { id },
});

export const getOrderSupportingDocsOk = response => ({
  type: GET_ORDER_SUPPORTING_DOCS_OK,
  payload: { response },
});

export const getOrderSupportingDocsErr = error => ({
  type: GET_ORDER_SUPPORTING_DOCS_ERR,
  payload: { error },
});

export const doOrderSupportingDocsCleanup = () => ({
  type: DO_ORDER_SUPPORTING_DOCS_CLEANUP,
});

/********** PREVIEW DOCS **************/

export const getOrderPreviewDocs = id => ({
  type: GET_ORDER_PREVIEW_DOCS,
  payload: { id },
});

export const getOrderPreviewDocsOk = response => ({
  type: GET_ORDER_PREVIEW_DOCS_OK,
  payload: { response },
});

export const getOrderPreviewDocsErr = error => ({
  type: GET_ORDER_PREVIEW_DOCS_ERR,
  payload: { error },
});

export const doOrderPreviewDocsCleanup = () => ({
  type: DO_ORDER_PREVIEW_DOCS_CLEANUP,
});

/********** ACTIVITY **********/

export const getOrderActivity = (id, limit) => ({
  type: GET_ORDER_ACTIVITY,
  payload: { id, limit },
});

export const getOrderActivityOk = response => ({
  type: GET_ORDER_ACTIVITY_OK,
  payload: { response },
});

export const getOrderActivityErr = error => ({
  type: GET_ORDER_ACTIVITY_ERR,
  payload: { error },
});

export const doOrderActivityCleanup = () => ({
  type: DO_ORDER_ACTIVITY_CLEANUP,
});

/********** MESSAGES **********/

export const getOrderMessages = (orderId, channelId, params) => ({
  type: GET_ORDER_MESSAGES,
  payload: { orderId, channelId, params }
});

export const getOrderMessagesOk = (response, channelId) => ({
  type: GET_ORDER_MESSAGES_OK,
  payload: { response, channelId }
});

export const getOrderMessagesErr = error => ({
  type: GET_ORDER_MESSAGES_ERR,
  payload: { error }
});

export const doOrderMessagesCleanup = () => ({
  type: DO_ORDER_MESSAGES_CLEANUP,
});

export const getChatMembers = (orderId, channelId) => ({
  type: GET_CHAT_MEMBERS,
  payload: { orderId, channelId }
});

export const getChatMembersOk = (response, channelId) => ({
  type: GET_CHAT_MEMBERS_OK,
  payload: { response, channelId }
});

export const getChatMembersErr = error => ({
  type: GET_CHAT_MEMBERS_ERR,
  payload: { error }
});

/********** SCHEDULER **********/

export const assignOrderScheduler = (data, orderId) => ({
  type: ASSIGN_ORDER_SCHEDULER,
  payload: { data, orderId },
});

export const assignOrderSchedulerOk = response => ({
  type: ASSIGN_ORDER_SCHEDULER_OK,
  payload: { response },
});

export const assignOrderSchedulerErr = error => ({
  type: ASSIGN_ORDER_SCHEDULER_ERR,
  payload: { error },
});

export const doOrderSchedulerAssignmentCleanup = () => ({
  type: DO_ORDER_SCHEDULER_ASSIGNMENT_CLEANUP,
});

/********** NOTARY BID **********/

export const awardNotaryBid = id => ({
  type: AWARD_NOTARY_BID,
  payload: { id },
});

export const awardNotaryBidOk = response => ({
  type: AWARD_NOTARY_BID_OK,
  payload: { response },
});

export const awardNotaryBidErr = error => ({
  type: AWARD_NOTARY_BID_ERR,
  payload: { error },
});

export const declineNotaryBid = id => ({
  type: DECLINE_NOTARY_BID,
  payload: { id },
});

export const declineNotaryBidOk = response => ({
  type: DECLINE_NOTARY_BID_OK,
  payload: { response },
});

export const declineNotaryBidErr = error => ({
  type: DECLINE_NOTARY_BID_ERR,
  payload: { error },
});

export const notifyNotaryBid = id => ({
  type: NOTIFY_NOTARY_BID,
  payload: { id },
});

export const notifyNotaryBidOk = response => ({
  type: NOTIFY_NOTARY_BID_OK,
  payload: { response },
});

export const notifyNotaryBidErr = error => ({
  type: NOTIFY_NOTARY_BID_ERR,
  payload: { error },
});

export const addOrderNotaryBid = (orderId, notaryId) => ({
  type: ADD_ORDER_NOTARY_BID,
  payload: { orderId, notaryId },
});

export const addOrderNotaryBidOk = response => ({
  type: ADD_ORDER_NOTARY_BID_OK,
  payload: { response },
});

export const addOrderNotaryBidErr = error => ({
  type: ADD_ORDER_NOTARY_BID_ERR,
  payload: { error },
});

export const startOrderNotaryBid = id => ({
  type: START_ORDER_NOTARY_BID,
  payload: { id },
});

export const startOrderNotaryBidOk = response => ({
  type: START_ORDER_NOTARY_BID_OK,
  payload: { response },
});

export const startOrderNotaryBidErr = error => ({
  type: START_ORDER_NOTARY_BID_ERR,
  payload: { error },
});

export const doNotaryBidCleanup = () => ({
  type: DO_NOTARY_BID_CLEANUP,
})

/********** NOTARY BID DATATABLE **************/

export const getOrderNotaryBidDt = id => ({
  type: GET_ORDER_NOTARY_BID_DT,
  payload: { id },
});

export const getOrderNotaryBidDtOk = response => ({
  type: GET_ORDER_NOTARY_BID_DT_OK,
  payload: { response },
});

export const getOrderNotaryBidDtErr = error => ({
  type: GET_ORDER_NOTARY_BID_DT_ERR,
  payload: { error },
});

export const doOrderNotaryBidDtCleanup = () => ({
  type: DO_ORDER_NOTARY_BID_DT_CLEANUP,
});

export const startNotaryRequeue = (id, notaryId) => ({
  type: START_NOTARY_REQUEUE,
  payload: { id, notaryId },
});

export const startNotaryRequeueOk = response => ({
  type: START_NOTARY_REQUEUE_OK,
  payload: { response },
});

export const startNotaryRequeueErr = error => ({
  type: START_NOTARY_REQUEUE_ERROR,
  payload: { error },
});

/********** DATATABLE **********/

export const getOrderNotaryDt = (id, params) => ({
  type: GET_ORDER_NOTARY_DT,
  payload: { id, params },
});

export const getOrderNotaryDtOk = response => ({
  type: GET_ORDER_NOTARY_DT_OK,
  payload: { response },
});

export const getOrderNotaryDtErr = error => ({
  type: GET_ORDER_NOTARY_DT_ERR,
  payload: { error },
});

export const doOrderNotaryDtCleanup = () => ({
  type: DO_ORDER_NOTARY_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyOrderNotaryDtFilters = filters => ({
  type: APPLY_ORDER_NOTARY_DT_FILTERS,
  payload: { filters },
});

export const clearOrderNotaryDtFilters = () => ({
  type: CLEAR_ORDER_NOTARY_DT_FILTERS,
});

/********** NOTARY MANUAL SEARCH **********/

/********** DATATABLE **********/

export const getSearchNotaryDt = params => ({
  type: GET_ORDER_NOTARY_DT,
  payload: { params },
});

export const getSearchNotaryDtOk = response => ({
  type: GET_ORDER_NOTARY_DT_OK,
  payload: { response },
});

export const getSearchNotaryDtErr = error => ({
  type: GET_ORDER_NOTARY_DT_ERR,
  payload: { error },
});

export const doSearchNotaryDtCleanup = () => ({
  type: DO_ORDER_NOTARY_DT_CLEANUP,
});


/********** DATATABLE FILTERS **********/

export const applySearchNotaryDtFilters = filters => ({
  type: APPLY_ORDER_NOTARY_DT_FILTERS,
  payload: { filters },
});

export const clearSearchNotaryDtFilters = () => ({
  type: CLEAR_ORDER_NOTARY_DT_FILTERS,
});

export const patchSearchNotaryDtFilters = filters => ({
  type: PATCH_ORDER_NOTARY_DT_FILTERS,
  payload: { filters },
});

export const unsetSearchNotaryDtFilters = () => ({
  type: UNSET_ORDER_NOTARY_DT_FILTERS,
});

export const doNotaryDtFiltersCleanup = () => ({
  type: DO_ORDER_NOTARY_DT_FILTERS_CLEANUP,
});

/********** NOTARY ORDERS DATATABLE **********/

export const getNotaryOrderDt = (id, params) => ({
  type: GET_NOTARY_ORDER_DT,
  payload: { id, params },
})

export const getNotaryOrderDtOk = response => ({
  type: GET_NOTARY_ORDER_DT_OK,
  payload: { response },
});

export const getNotaryOrderDtErr = error => ({
  type: GET_NOTARY_ORDER_DT_ERR,
  payload: { error },
});

export const doNotaryOrderDtCleanup = () => ({
  type: DO_NOTARY_ORDER_DT_CLEANUP,
});