import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Label, Button, ButtonGroup, Input } from "reactstrap";
import Order from 'model/order';
import VidRequest from 'model/vidRequest';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from 'helpers/utilHelper';
import { getFullOrder } from 'store/actions';
import Confirmation from 'components/Shared/Confirmation';
import eSignServiceIcon from 'assets/images/e-sign-service-icon.svg';
import rushOrderServiceIcon from 'assets/images/rush-order-icon.svg';
import editIcon from "assets/images/edit-icon.svg";
import editServiceIcon from "assets/images/edit-services-icon.svg";
import passedVid from "assets/images/passed-VID.svg";
import {
  toggleRushService,
  addVIDService,
  removeVIDService,
  addESign,
  removeESign,
  getESignOrderDocs,
} from "helpers/backendHelper";
import { perms, useAccess } from "context/access";
import Col from "components/Shared/Col";
import {
  ORDER_CANNOT_REMOVE_ESIGN_SERVICE_WITH_DOCS,
  ORDER_CANNOT_REMOVE_THE_ONLY_SERVICE,
  ORDER_ESIGN_SERVICE_ALREADY_ADDED,
  ORDER_ESIGN_SERVICE_NOT_ADDED,
  ORDER_INVALID_STATUS_FOR_RUSH_OPERATIONS,
  ORDER_INVALID_STATUS_FOR_VID_OPERATIONS,
  ORDER_STATUS_INVALID_FOR_ADD_REMOVE_ESIGN
} from 'helpers/errorHelper';

const SectionOrderServices = ({ order, id, refreshHandler }) => {

  const dispatch = useDispatch();
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const [editServicesVisible, setEditServicesVisible] = useState(false);

  //  Services modals
  const [confirmOrderServicesVisible, setConfirmOrderServicesVisible] = useState(false);
  const [confirmAddEsignServicesVisible, setConfirmAddEsignServicesVisible] = useState(false);
  const [confirmRemoveEsignServicesVisible, setConfirmRemoveEsignServicesVisible] = useState(false);
  const [confirmDeleteDocumentsFirstVisible, setConfirmDeleteDocumentsFirstVisible] = useState(false);

  // Order Services - RUSH, ADD/REMOVE VID, ESIGN
  const [isToggleRushChanged, setIsToggleRushChanged] = useState(false);

  const [isAddVidChanged, setIsAddVidChanged] = useState(false);
  const [isRemoveVidChanged, setIsRemoveVidChanged] = useState(false);
  const [isVidServiceChanged, setIsVidServiceChanged] = useState(false);

  const [isAddEsignChanged, setIsAddEsignChanged] = useState(false);
  const [isRemoveEsignChanged, setIsRemoveEsignChanged] = useState(false);
  const [isEsignServiceChanged, setIsEsignServiceChanged] = useState(false);

  const [isEsignChecked, setIsEsignChecked] = useState(order.isEsignRequired);

  const [eSignOrderDocs, setESignOrderDocs] = useState([]);

  /********* CONSTANTS **********/

  const refreshOrder = useCallback(() => dispatch(getFullOrder(id)), [id]);

  const isDocDeliveryUpload = order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_UPLOAD;

  const isVidOnly = () => !order.isEsignRequired && !order.isInkSignRequired;

  const isEsignOnly = () => !order.isVidRequired && !order.isInkSignRequired;

  const isOrderCompleted = () => order.status === Order.STATUS_COMPLETED;

  const isOrderSeald = () => order.status === Order.STATUS_SEALED;

  const isEditServicesVisible = () => iAmGranted(perms.add_order_services) && !isOrderCompleted() && !isOrderSeald();

  // check if can edit RUSH service
  // (only if an order have a notary and is in New status or pending vid)
  const canEditRushServiceStatuses = () => order.status === Order.STATUS_NEW || order.status === Order.STATUS_PENDING_VID;
  const canEditRushService = () => order.isNotaryRequired && canEditRushServiceStatuses();

  // Can Add/Remove VID for:
  // order with notary and before reaching the Pick Notary status
  // order without notary - with ink sign - and while is in Pending Ink-Sign
  // order without notary - with esign - and while is in Pending E-sign
  // and it can be removed only if no action was performed

  const canAddVIDService = () => (order.isNotaryRequired && !order.isVidRequired && (order.status == Order.STATUS_PENDING_VID || order.status === Order.STATUS_NEW)) ||
    ([Order.STATUS_PENDING_INK_SIGN, Order.STATUS_PENDING_E_SIGN].includes(order.status) && !order.isNotaryRequired);

  const canRemoveVIDService = () => (order.status == Order.STATUS_PENDING_VID && (order.vidStatus === VidRequest.STATUS_CREATED || order.vidStatus === VidRequest.STATUS_PENDING));

  // Can Add Esign on order whenever it is not completed or cancelled
  const canAddEsignService = () => !order.isEsignRequired && ![Order.STATUS_CANCELLED, Order.STATUS_COMPLETED].includes(order.status);

  // Esign can be removed from the order as long as there are no documents added in the e-sign section
  // is not cancelled or completed and is not the only service on the order.
  const canRemoveEsignService = () => !isEsignOnly() && ![Order.STATUS_CANCELLED, Order.STATUS_COMPLETED].includes(order.status);

  const isEditServiceDisabled = () => !canEditRushService() &&
    !(canAddVIDService() || canRemoveVIDService()) &&
    (!isDocDeliveryUpload || !(canAddEsignService() || canRemoveEsignService()));


  /********** HELPERS **********/

  const refreshESignOrderDocs = () => {
    getESignOrderDocs(id)
      .then(response => {
        setESignOrderDocs(response.orderDocs);
      })
      .catch(err => {
      })
  };

  const resetServices = () => {
    // Reset services state
    setIsToggleRushChanged(false);

    // VID Services
    setIsVidServiceChanged(false);
    setIsAddVidChanged(false);
    setIsRemoveVidChanged(false);

    // E-sign Services
    setIsEsignServiceChanged(false);
    setIsAddEsignChanged(false);
    setIsRemoveEsignChanged(false);

    // Close confirm services modal
    setConfirmOrderServicesVisible(false);
  }

  const getVidServiceStatus = (addVIDService) => {
    if (addVIDService) {
      setIsAddVidChanged(true);
      setIsRemoveVidChanged(false);
    } else {
      setIsRemoveVidChanged(true);
      setIsAddVidChanged(false);
    }
    setIsVidServiceChanged(true);
  };

  const getEsignServiceStatus = (eSignService) => {
    // Close edit services modal
    setEditServicesVisible(false);

    if (!eSignService) {
      // If there are documents in the Esgin section and the user wants to remove the esign service
      // display a message to remove the documents first
      if (eSignOrderDocs?.length > 0) setConfirmDeleteDocumentsFirstVisible(true);

      // If there are no documents in the Esgin section and the user wants to remove the esign service
      // Open confirm Remove Esign service modal
      if (eSignOrderDocs?.length === 0) setConfirmRemoveEsignServicesVisible(true);
    }

    if (eSignService) {
      // Open confirm Add Esign service modal
      setConfirmAddEsignServicesVisible(true);
    }

    setIsEsignServiceChanged(true);
  }

  // Add / Remove RUSH Service
  const handleToggleRushService = () => {
    toggleRushService(id)
      .then(() => {
        showSuccess("Rush service modified with success.");
        refreshOrder();
        refreshHandler();
      })
      .catch((error) => {
        if (error.code == ORDER_INVALID_STATUS_FOR_RUSH_OPERATIONS) {
          showError("Invalid status for rush operations.");
        } else showError("Unable to modify Rush service.");
      })
  }

  //  Add VID Service
  const handleAddVIDService = () => {
    addVIDService(id)
      .then(() => {
        showSuccess("Verify ID service added with success.");
        refreshOrder();
        refreshHandler();
      })
      .catch((error) => {
        if (error.code == ORDER_INVALID_STATUS_FOR_VID_OPERATIONS) {
          showError("Invalid status for adding Verify ID service.");
        } else {
          showError("Unable to modify Verify ID service.")
        }
      })
  }

  //  Remove VID Service
  const handleRemoveVIDService = () => {
    removeVIDService(id)
      .then(() => {
        showSuccess("Verify ID service removed with success.");
        refreshOrder();
        refreshHandler();
      })
      .catch((error) => {
        if (error.code === 1266) {
          showError("Invalid status for removing Verify ID service.");
        } else {
          showError("Unable to modify Verify ID service.")
        }
      })
  }

  //  Add Esign Service
  const handleAddEsignService = () => {
    addESign(id)
      .then(() => {
        showSuccess("E-sign service added with success.");
        refreshOrder();
        refreshESignOrderDocs();
        refreshHandler();
      })
      .catch((error) => {
        switch (error.code) {
          case ORDER_ESIGN_SERVICE_ALREADY_ADDED:
            showError("E-sign service already added");
            break;
          case ORDER_STATUS_INVALID_FOR_ADD_REMOVE_ESIGN:
            showError("Service cannot be added in this order status");
            break;
          default:
            showError("Unable to add e-sign service");
            break;
        }
      });
  }

  //  Remove Esign Service
  const handleRemoveEsignService = () => {
    removeESign(id)
      .then(() => {
        showSuccess("E-sign service removed with success.");
        refreshOrder();
        refreshESignOrderDocs();
        refreshHandler();
      })
      .catch((error) => {
        switch (error.code) {
          case ORDER_ESIGN_SERVICE_NOT_ADDED:
            showError("E-sign service not present");
            break;
          case ORDER_CANNOT_REMOVE_ESIGN_SERVICE_WITH_DOCS:
            showError("Please remove all e-sign documents before removing the service");
            break;
          case ORDER_STATUS_INVALID_FOR_ADD_REMOVE_ESIGN:
            showError("Service cannot be removed in this order status");
            break;
          case ORDER_CANNOT_REMOVE_THE_ONLY_SERVICE:
            showError("Cannot remove the only service");
            break;
          default:
            showError("Unable to remove e-sign service");
            break;
        }
      })
  }

  // Confirm order services - RUSH, ADD/REMOVE VID, ADD/REMOVE E-SIGN
  const handleConfirmOrderServices = () => {
    if (isToggleRushChanged) {
      handleToggleRushService();
    }

    if (isVidServiceChanged) {
      if (isAddVidChanged) handleAddVIDService();

      if (isRemoveVidChanged) handleRemoveVIDService();
    }

    if (isEsignServiceChanged) {
      if (isAddEsignChanged) handleAddEsignService();

      if (isRemoveEsignChanged) handleRemoveEsignService();
    }

    // Reset services state
    resetServices();
  }



  return <React.Fragment>
    <div className="d-flex align-items-center justify-content-start display-action-buttons">
      {/* Edit services - Vid and Rush */}
      {isEditServicesVisible() && <ButtonGroup className="pe-2">
        <Button color="primary" className="square-icon-btn" onClick={() => setEditServicesVisible(true)}>
          <img src={editIcon} alt="edit-icon" />
        </Button>
        <Button color="primary" className="btn py-1" onClick={() => setEditServicesVisible(true)}>
          Edit Services
        </Button>
      </ButtonGroup>}
    </div>

    {/********* MODALS *********/}

    {/* Services Modal */}
    {editServicesVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 600, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      title="Edit Services"
      confirmBtnText="Confirm"
      cancelBtnText="Close"
      onConfirm={() => {
        setEditServicesVisible(false);
        setConfirmOrderServicesVisible(true);
      }}
      onCancel={() => {
        setIsToggleRushChanged(false);
        setIsVidServiceChanged(false);
        setIsEsignServiceChanged(false);

        setEditServicesVisible(false);
        // reset the check value for eSign with the value from the order
        setIsEsignChecked(order.isEsignRequired);
      }}
      closeOnClickOutside={false}
      reverseButtons={false}
      disabled={isEditServiceDisabled()}
    >
      <div className="d-flex justify-content-center align-items-center my-3">
        <Col sm={8}>
          {!isVidOnly() && <React.Fragment>
            {/* Verify ID - Service */}
            <Row>
              <Col sm={6}>
                <div className="d-flex align-items-center">
                  <img src={passedVid} className="me-2" />
                  <div className="font-size-13 font-weight-normal">Verify ID</div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="form-check form-switch form-switch-lg d-inline-block">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="verifyID"
                    onChange={e => getVidServiceStatus(e.target.checked)}
                    defaultChecked={order.isVidRequired}
                    disabled={!(canAddVIDService() || canRemoveVIDService())}
                  />
                  <Label className="form-check-label" htmlFor="verifyID" />
                </div>
              </Col>
            </Row>

            {/* Rush order - Service */}
            <Row className="mt-2">
              <Col sm={6}>
                <div className="d-flex align-items-center">
                  <img src={rushOrderServiceIcon} className="me-2" />
                  <div className="font-size-13 font-weight-normal">Rush order</div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="form-check form-switch form-switch-lg d-inline-block">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="rush"
                    onChange={() => setIsToggleRushChanged(true)}
                    defaultChecked={order.isRushRequired}
                    disabled={!canEditRushService()}
                  />
                  <Label className="form-check-label" htmlFor="rush" />
                </div>
              </Col>
            </Row>
          </React.Fragment>}

          {(!isEsignOnly() && isDocDeliveryUpload) && <React.Fragment>
            {/* E-sign - Service */}
            <Row className="mt-2">
              <Col sm={6}>
                <div className="d-flex align-items-center">
                  <img src={eSignServiceIcon} className="me-2" />
                  <div className="font-size-13 font-weight-normal">E-sign</div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="form-check form-switch form-switch-lg d-inline-block">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="esign"
                    onChange={e => {
                      getEsignServiceStatus(e.target.checked)
                    }}
                    checked={isEsignChecked}
                    //defaultChecked={}
                    disabled={!(canAddEsignService() || canRemoveEsignService())}
                  />
                  <Label className="form-check-label" htmlFor="esign" />
                </div>
              </Col>
            </Row>
          </React.Fragment>}
        </Col>
      </div>
    </Confirmation>}

    {/* Confirm Add Order Services Modal */}
    {confirmOrderServicesVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      onConfirm={() => handleConfirmOrderServices()}
      onCancel={() => resetServices()}
      closeOnClickOutside={false}
      reverseButtons={false}
    >
      <div className="d-flex justify-content-center align-items-center">
        <Col sm={8}>
          <span style={{ color: '#556EE6', fontSize: 15 }}>Changing the settings here will impact the progress of the order and may entail additional fees!</span>
        </Col>
      </div>
    </Confirmation>}

    {/* ADD E-sign - Modal */}
    {confirmAddEsignServicesVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      onConfirm={() => {
        setIsAddEsignChanged(true);
        // see in the UI the e-sign service checked
        setIsEsignChecked(true);
        setIsRemoveEsignChanged(false);

        // close add esign modal
        setConfirmAddEsignServicesVisible(false);
        // open edit services modal
        setEditServicesVisible(true);
      }}
      onCancel={() => {
        setIsAddEsignChanged(false);

        // close add e-sign message(modal)
        setConfirmAddEsignServicesVisible(false);
        // open edit services modal
        setEditServicesVisible(true);
      }}
      closeOnClickOutside={false}
      reverseButtons={false}
    >
      <div className="d-flex justify-content-center">
        <Col sm={10}>
          <span style={{ color: '#556EE6', fontSize: 15, textAlign: 'left' }}>Electronic Signature will be added as a service on this order. The documents to be signed need to be uploaded in the <strong>E-Signed Documents</strong>. After the signature placement, the Customer will be notified to Electronically Sign the documents.</span>
        </Col>
      </div>
    </Confirmation>}

    {/* REMOVE E-sign - Modal */}
    {confirmRemoveEsignServicesVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      onConfirm={() => {
        setIsRemoveEsignChanged(true);
        // see in the UI the e-sign service unchecked
        setIsEsignChecked(false);
        setIsAddEsignChanged(false);

        // close confirm remove e-sign message(modal)
        setConfirmRemoveEsignServicesVisible(false);
        // open edit service modal
        setEditServicesVisible(true);
      }}
      onCancel={() => {
        setIsRemoveEsignChanged(false);

        // close confirm remove e-sign message(modal)
        setConfirmRemoveEsignServicesVisible(false);
        // open edit service modal
        setEditServicesVisible(true);
      }}
      closeOnClickOutside={false}
      reverseButtons={false}
    >
      <div className="d-flex justify-content-center">
        <Col sm={10}>
          <span style={{ color: '#556EE6', fontSize: 15, textAlign: 'left' }}>Electronic Signature will be removed. The service will no longer be available for this order.</span>
        </Col>
      </div>
    </Confirmation>}

    {/* Confirm Delete E-sign Documents First */}
    {confirmDeleteDocumentsFirstVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      showCancel={false}
      onConfirm={() => {
        // close confirm remove e-sign documents message(modal)
        setConfirmDeleteDocumentsFirstVisible(false);
        // open edit service modal
        setEditServicesVisible(true);
      }}
      closeOnClickOutside={false}
    >
      <div className="d-flex justify-content-center">
        <Col sm={10}>
          <span style={{ color: '#556EE6', fontSize: 15, textAlign: 'left' }}>There are Documents added in the <strong>E-Signed Documents</strong> section, so the E-sign service cannot be removed. First delete the documents, and then remove the E-sign service from the order.</span>
        </Col>
      </div>
    </Confirmation>}
  </React.Fragment>
}

SectionOrderServices.propTypes = {
  order: PropTypes.object,
  id: PropTypes.number,
  refreshHandler: PropTypes.func,
};

export default SectionOrderServices;
